import SinglePage from 'components/SinglePage';
import * as config from 'schemas/seminar';
import { Trans, useTranslation } from 'react-i18next';
import { ApiFilter } from 'lib';
import { useCallback, useMemo } from 'react';
import * as sessionConfig from 'schemas/session';
import ListPage from 'components/ListPage';
import { useInviteParticipant } from 'schemas/common/inviteParticipant';
import { Action } from 'components/Actions';
import { useUpdates } from 'providers/UpdatesProvider';

const SessionsList = ({
  seminar: { bahai_seminarid, bahai_statuscode, _bahai_leadfacilitatorid_value: leadFacilitator },
}: {
  seminar: Record<config.Keys | '_bahai_leadfacilitatorid_value', any>;
}) => {
  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_seminarid', value: bahai_seminarid }] }],
    [bahai_seminarid]
  );

  const getInitialValues = useCallback(async () => ({ bahai_seminarid }), [bahai_seminarid]);

  const isNotCreatable = useMemo(() => {
    if (config.SEMINAR_FINISHED === String(bahai_statuscode)) return <Trans>Seminar Status is Finished</Trans>;
  }, [bahai_statuscode]);

  const { action: inviteParticipantAction, content: inviteParticipantContent } = useInviteParticipant({
    entityName: 'participant',
    message: isNotCreatable,
    seminarId: bahai_seminarid,
  });

  const { action: inviteFacilitatorAction, content: inviteFacilitatorContent } = useInviteParticipant({
    entityName: 'lecturer',
    message: isNotCreatable,
    leadFacilitator,
    seminarId: bahai_seminarid,
  });

  const getActions = useCallback(
    (baseActions: Action[]) => baseActions.concat(inviteParticipantAction, inviteFacilitatorAction),
    [inviteFacilitatorAction, inviteParticipantAction]
  );

  const { fireUpdate } = useUpdates();
  const onItemCreated = useCallback(() => fireUpdate('seminar:bahai_yearsoffered'), [fireUpdate]);
  const onItemsRemoved = useCallback(
    () => fireUpdate(['seminar:bahai_yearsoffered', 'seminar:facilitators']),
    [fireUpdate]
  );

  return (
    <>
      {inviteParticipantContent}
      {inviteFacilitatorContent}
      <ListPage
        entityName="session"
        isSubgrid
        {...{
          ...sessionConfig,
          getActions,
          isNotCreatable,
          hiddenFilters,
          getInitialValues,
          onItemCreated,
          onItemsRemoved,
        }}
      />
    </>
  );
};

export const Seminar = () => {
  const { t } = useTranslation();

  return (
    <SinglePage
      entityName="seminar"
      displayAssign
      mainSectionName={<Trans>Basic Information</Trans>}
      asyncFields={['bahai_yearsoffered', 'bahai_participants', 'bahai_registrants', 'bahai_attendees']}
      additionalTabs={[
        {
          label: t('Sessions'),
          tab: 'session',
          isDefault: true,
          content: (data: Record<string, any>) => <SessionsList seminar={data} />,
        },
      ]}
      {...config}
    />
  );
};
