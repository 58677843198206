import SinglePage from 'components/SinglePage';
import * as config from 'schemas/application';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Keys } from 'schemas/application';
import { TopMessage } from 'components/TopMessages';
import { useChangeStatus } from 'schemas/common';
import { Action } from 'components/Actions';

export const Application = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const getTopMessages = useCallback(
    (data: Record<Keys, any>): TopMessage[] => (data.bahai_isdraft ? [{ message: t('Draft Application') }] : []),
    [t]
  );

  const { action, content } = useChangeStatus({
    id,
    entityName: 'application',
    fieldName: 'bahai_applicationstatuscode',
    online: true,
  });

  const getActions = useCallback((baseActions: Action[]) => baseActions.concat(action), [action]);

  return (
    <>
      {content}
      <SinglePage
        entityName="application"
        displayAssign
        {...config}
        mainSectionName={<Trans>Basic Information</Trans>}
        getTopMessages={getTopMessages}
        getActions={getActions}
        allowDraft
      />
    </>
  );
};
