import entities from 'config';
import { config as facilitatorConfig } from 'schemas/facilitator';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { TLinkEntity } from 'components/ListPage';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';

type Keys = (typeof entities.lecturer.columns)[number];

type FacilitatorKeys = `facilitator.${(typeof entities.facilitator.columns)[number]}`;

export const defaultMobileColumns: Array<Keys | FacilitatorKeys> = [
  'bahai_facilitatorid',
  'facilitator.bahai_phone',
  'bahai_seminarid',
];

export const links = {
  facilitator: entities.facilitator.columns,
  seminar: ['bahai_statuscode'],
} as TLinkEntity;

export const columns = [
  ...entities.lecturer.columns,
  ...entities.facilitator.columns.map((column) => `facilitator.${column}`),
];

export const config: AdditionalConfigType<Keys | FacilitatorKeys | 'seminar.bahai_statuscode'> = {
  bahai_facilitatorid: {
    searchable: true,
  },
  'seminar.bahai_statuscode': {
    hiddenForTable: true,
  },
  ...Object.fromEntries(
    Object.entries(facilitatorConfig).map(([key, props]) => ['facilitator.' + key, { ...props, searchable: false }])
  ),
  'facilitator.bahai_id': {
    component: ({ data }) => (
      <HistoryLink to={r.facilitator({ id: data['_bahai_facilitatorid_value' as Keys] || 0 })}>
        {data['facilitator.bahai_id']}
      </HistoryLink>
    ),
  },
};

export const getFormConfig: FormConfigGetter<Keys> = () => [];
