import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { Trans } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { EmailComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';
import { TopMessage } from 'components/TopMessages';

export type Keys = (typeof entities.coordinator.columns)[number];

export const defaultMobileColumns: Keys[] = ['fullname', 'homephone', 'businessunitid'];

export const columns = entities.coordinator.columns;

export const config: AdditionalConfigType<Keys | 'systemuserid'> = {
  entityimage: { hiddenForTable: true, excludeFromListQuery: true },
  isdisabled: { hiddenForTable: true },
  createdby: { hiddenForView: true, hiddenForTable: true },
  createdon: { hiddenForView: true, hiddenForTable: true },
  bahai_usstate: { sortable: false },
  bahai_isgpregion: { sortable: false },
  bahai_unit: { sortable: false },
  internalemailaddress: { searchable: true, component: EmailComponent },
  fullname: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink to={r.coordinator({ id: data.systemuserid || 0 })}>{data.fullname}</HistoryLink>
    ),
  },
  homephone: { hiddenForView: true, hiddenForTable: true },
  middlename: { hiddenForView: true, hiddenForTable: true },
};

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  middlename: rules.maxLength(25),
  homephone: rules.phone,
};

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [<Trans>Personal Information</Trans>, ['firstname', 'lastname', 'bahai_homeunitid'] as Keys[]],
  [<Trans>Area Served</Trans>, ['bahai_unit', 'bahai_usstate', 'bahai_isgpregion', 'bahai_wholecountry'] as Keys[]],
];

export const getExtraFields = (): Keys[] => ['internalemailaddress'];
export const getCommonFields = (): Keys[] => ['businessunitid', 'createdon'];

export const getFormConfig: FormConfigGetter<Keys> = () => [];

export const getTopMessages = (data: Record<string, any>) =>
  data.isdisabled
    ? ([{ message: <Trans>Record State is Inactive</Trans>, Icon: `info` }] as TopMessage[])
    : ([] as TopMessage[]);
