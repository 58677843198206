import { ReactNode, useCallback, useMemo } from 'react';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AttendedIcon } from './icons/attended.svg';
import { ReactComponent as NotAttendedIcon } from './icons/notAttended.svg';
import { removePageFromQuery, useMetaData } from 'lib/hooks';
import { useNotifications } from 'providers/NotificationsProvider';
import { useRecord } from 'lib/record';
import { useListRecords } from 'domain/operations';
import { useLoader } from 'providers/LoaderProvider';
import { SEMINAR_FINISHED } from 'schemas/seminar';
import { useLocation } from 'react-router-dom';
import { useUpdates } from 'providers/UpdatesProvider';

export const useAttendance = () => {
  const { t } = useTranslation();
  const { addActionCompleted } = useNotifications();
  const { showLoader, hideLoader } = useLoader();
  const { pathname } = useLocation();

  const personContext = useMemo(() => pathname.startsWith('/person'), [pathname]);

  const { patch } = useRecord('participant');
  const { url, PrimaryIdAttribute, PrimaryNameAttribute } = useMetaData('participant');
  const getRecords = useListRecords(url, { id: PrimaryIdAttribute, name: PrimaryNameAttribute });

  const inviteSelected = useCallback(
    async (selected: Record<string, any>[], bahai_attendancestatus: boolean) => {
      let index = 0;
      let success = 0;
      const errors: { label: string; content: ReactNode }[] = [];
      while (index < selected.length) {
        try {
          showLoader(selected.length > 1 ? `Processing ${index + 1} / ${selected.length}` : undefined);
          await patch({ bahai_attendancestatus }, selected[index].bahai_participantid);
          success++;
        } catch (error) {
          errors.push({ label: selected[index].bahai_name, content: (error as Record<string, any>).message });
        }
        index++;
      }
      hideLoader();
      return { errors, success };
    },
    [hideLoader, patch, showLoader]
  );

  const getSuccessMessage = useCallback(
    (value: boolean, all: boolean) => {
      switch (true) {
        case value && all:
          return t('Participants were marked as Attended');
        case !value && all:
          return t('Participants were marked as Not Attended');
        case value && !all:
          return t('Participant was marked as Attended');
        default:
          return t('Participant was marked as Not Attended');
      }
    },
    [t]
  );

  const { fireUpdate } = useUpdates();
  const fireUpdates = useCallback(
    () => fireUpdate(['session:bahai_attendees', 'seminar:bahai_attendees']),
    [fireUpdate]
  );

  const invite = useCallback(
    ({
      selectedItems,
      query,
      reload,
      value,
    }: {
      selectedItems: Record<string, any>[];
      query: any;
      reload: () => void;
      value: boolean;
    }) => {
      showLoader('Processing');
      const getItems = async () =>
        selectedItems.length
          ? selectedItems
          : (await getRecords(removePageFromQuery(query))).map(({ id, name }) => ({
              bahai_participantid: id,
              bahai_name: name,
            }));
      getItems()
        .then((items) => inviteSelected(items, value))
        .then(({ success }) => {
          fireUpdates();
          addActionCompleted(getSuccessMessage(value, success > 1));
          reload();
        });
    },
    [addActionCompleted, fireUpdates, getRecords, getSuccessMessage, inviteSelected, showLoader]
  );

  const actions: Action[] = useMemo(
    () => [
      {
        title: t('Mark All Attended'),
        name: 'attendAll',
        onClick: (props) => invite({ ...props, selectedItems: [], value: true }),
        order: 23,
        Icon: AttendedIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItems, data }) =>
          data.length > 0 &&
          selectedItems.length === 0 &&
          !personContext &&
          String(data[0]['seminar.bahai_statuscode']) === SEMINAR_FINISHED,
        alwaysKeepTitle: true,
      },
      {
        title: t('Mark All Not Attended'),
        name: 'notAttendAll',
        onClick: (props) => invite({ ...props, selectedItems: [], value: false }),
        order: 23,
        Icon: NotAttendedIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItems, data }) =>
          data.length > 0 &&
          selectedItems.length === 0 &&
          !personContext &&
          String(data[0]['seminar.bahai_statuscode']) === SEMINAR_FINISHED,
        alwaysKeepTitle: true,
      },
      {
        title: t('Mark Attended'),
        name: 'attend',
        onClick: (props) => invite({ ...props, value: true }),
        order: 23,
        Icon: AttendedIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItems }) =>
          selectedItems
            .filter((v) => !!v)
            .some(
              ({ bahai_attendancestatus, 'seminar.bahai_statuscode': seminarStatus }) =>
                String(seminarStatus) === SEMINAR_FINISHED && bahai_attendancestatus !== true
            ) &&
          (!personContext || selectedItems.length === 1),
        alwaysKeepTitle: true,
      },
      {
        title: t('Mark Not Attended'),
        name: 'notAttend',
        onClick: (props) => invite({ ...props, value: false }),
        order: 23,
        Icon: NotAttendedIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItems }) =>
          selectedItems
            .filter((v) => !!v)
            .some(
              ({ bahai_attendancestatus, 'seminar.bahai_statuscode': seminarStatus }) =>
                String(seminarStatus) === SEMINAR_FINISHED && bahai_attendancestatus !== false
            ) &&
          (!personContext || selectedItems.length === 1),
        alwaysKeepTitle: true,
      },
    ],
    [invite, personContext, t]
  );

  return { actions };
};
