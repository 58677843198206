import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './palette.css';
import './i18n';

import { App } from 'pages';
import reportWebVitals from './reportWebVitals';
import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'domain/authConfig';
import { AuthProvider } from 'providers/AuthProvider';
import { ScreenProvider } from 'providers/ScreenProvider';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { MetaDataProvider } from 'providers/MetaDataProvider';
import { CustomDragLayer } from 'components/CustomDragLayer';
import { UserSettingsProvider } from 'providers/UserSettingsProvider';
import { NotificationsProvider } from 'providers/NotificationsProvider';
import { UpdatesProvider } from 'providers/UpdatesProvider';
import { LoaderProvider } from 'providers/LoaderProvider';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserChecker } from 'components/BrowserChecker';
import { MsalBlocker } from 'providers/MsalAuthProvider';
import { MetaChecker } from 'components/MetaChecker';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  switch (event.eventType) {
    case EventType.LOGIN_SUCCESS: {
      const account = (event.payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
      break;
    }
    case EventType.LOGOUT_SUCCESS:
      msalInstance.getAllAccounts().length > 0 && msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      break;
    default:
      break;
  }
});

const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
const environment = process.env.REACT_APP_ENV || 'dev';
const version = process.env.REACT_APP_VERSION;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment,
    dsn: 'https://354474dc5ec5a23e8bb14bf528d7550a@o2701.ingest.us.sentry.io/4507921988386816',
    integrations: [Sentry.browserProfilingIntegration(), Sentry.replayIntegration()], // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production! // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

Sentry.setTag('version', version);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <LoaderProvider>
    <NotificationsProvider>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <MsalBlocker>
            <AuthProvider>
              {environment === 'dev' && <MetaChecker />}
              <ScreenProvider>
                <MetaDataProvider>
                  <UserSettingsProvider>
                    <UpdatesProvider>
                      <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
                        <CustomDragLayer />
                        <BrowserChecker />
                        <App />
                      </DndProvider>
                    </UpdatesProvider>
                  </UserSettingsProvider>
                </MetaDataProvider>
              </ScreenProvider>
            </AuthProvider>
          </MsalBlocker>
        </BrowserRouter>
      </MsalProvider>
    </NotificationsProvider>
  </LoaderProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
