import { Modal } from 'components/Modal';
import { Field, Form } from 'react-final-form';
import { Input } from 'components/Form';
import * as rules from 'lib/rules';
import { useTranslation } from 'react-i18next';
import { FormApi } from 'final-form';
import { createPortal } from 'react-dom';
import { ReactNode } from 'react';
import { Control } from 'components/Panel';

type TRemoveModalProps = {
  onRemove: (data: { Message?: string }, form: FormApi<{ Message: string }>) => void;
  title: ReactNode;
  action: ReactNode;
  header?: ReactNode;
  onClose: () => void;
  isConfirmationMessageNeeded?: boolean;
  isConfirmationMessageRequired?: boolean;
  progress?: JSX.Element | null;
};

export const RemoveModal = ({
  onRemove,
  action,
  title,
  header,
  isConfirmationMessageNeeded,
  isConfirmationMessageRequired,
  onClose,
  progress,
}: TRemoveModalProps) => {
  const { t } = useTranslation();

  if (progress) return progress;

  return createPortal(
    <Form
      onSubmit={onRemove}
      initialValues={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            {...{
              portal: false,
              title,
              header,
              onClose,
              controls: [
                { title: action, type: 'submit', role: 'primary' },
                {
                  title: t('Cancel'),
                  onClick: onClose,
                },
              ] as Control[],
            }}
          >
            {isConfirmationMessageNeeded && (
              <Field
                name="Message"
                component={Input}
                label={t('Confirmation Text')}
                validate={isConfirmationMessageRequired ? rules.confirmation : rules.length(2, 256)}
                required={isConfirmationMessageRequired}
                maxLength={256}
                inputType="area"
              />
            )}
          </Modal>
        </form>
      )}
    />,
    document.body
  );
};
