import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { Trans } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { EmailComponent, PhoneComponent, AreaTooltipComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';
import { subYears, isAfter } from 'date-fns';
import { Education } from 'pages/Person/components/Education';
import { TLinkEntity } from 'components/ListPage';
import { ClearImprover } from 'lib/improvers';
import { TopMessage } from 'components/TopMessages';
import Tooltip from 'components/Tooltip';
import { ReactComponent as InfoIcon } from './info.svg';
import { getWarningImprover } from 'components/ListPage/components/Form';
import { ReactNode } from 'react';
import { RecordStatusIsArchive } from 'schemas/common';
import { addressFieldsConfig } from 'lib/addressValidation';

export type Keys =
  | (typeof entities.person.columns)[number]
  | 'bahai_personid'
  | 'state.bahai_stateregionid'
  | 'systemuserid';

export const INVITED = 370790001;
export const ALUMNI = 370790007;
export const REGISTERED = 370790008;
export const APPLIED_NOT_ATTENDED = 370790009;

const specialConsiderationFlag = (
  <Trans>
    Person is marked with Special Considerations, please consult with Regional/National team before taking further
    action
  </Trans>
);

const specialConsiderationLabel = (
  <>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        lineHeight: '20px',
      }}
    >
      <Trans>Special Consideration</Trans>
      <Tooltip content="Please, consult with Regional/National team for further information">
        <InfoIcon style={{ marginLeft: '5px' }} />
      </Tooltip>
    </div>
  </>
);

export const isActive = (data: Record<Keys, any>) => !!data.bahai_state;

export const isNotEditable = (data: Record<Keys, any>) => (!isActive(data) ? RecordStatusIsArchive : false);

export const defaultMobileColumns: Keys[] = ['bahai_name', 'bahai_email', 'bahai_id'];

export const columns = [...entities.person.columns, 'state.bahai_stateregionid'];
export const widgets = [Education];

export const links = {
  state: entities.state.columns,
} as TLinkEntity;

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink
        to={r.person({
          id: data.bahai_personid || (data as Record<string, any>)['_bahai_personid_value'] || 0,
        })}
      >
        {data.bahai_id || (data as Record<string, any>)['person.bahai_id']}
      </HistoryLink>
    ),
  },
  bahai_name: { searchable: true },
  bahai_firstname: { isRequired: true },
  bahai_lastname: { isRequired: true },
  bahai_email: { component: EmailComponent, isRequired: true, searchable: true },
  bahai_additionalemail: { component: EmailComponent },
  bahai_phone: {
    isRequired: (values) => !values.bahai_phoneinput,
    component: (props) => (
      <PhoneComponent {...props} name={props.data.bahai_phone ? 'bahai_phone' : 'bahai_phoneinput'} />
    ),
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_phoneinput: { isDisabled: (_, data) => !!data?.id, component: PhoneComponent },
  bahai_profileimage: { hiddenForTable: true, excludeFromListQuery: true },
  bahai_dateofbirth: {
    fieldProps: () => ({ canSelectFutureDate: false }),
    isRequired: true,
  },
  bahai_nickname: { fieldProps: () => ({ maxLength: 30 }) },
  bahai_note: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_gendercode: { isRequired: true },
  bahai_statusnote: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
    }),
  },
  bahai_yearscompleted: { sortable: false },
  bahai_internationalcountry: { isRequired: true, fieldProps: () => ({ maxLength: 100 }) },
  bahai_international: {
    fieldProps: ({ classes }) => ({ renderAs: 'Switch', className: classes.long }),
  },
  bahai_homeuniversitystate: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({ maxLength: 500, className: classes.long }),
  },
  bahai_specialconsideration: {
    label: specialConsiderationLabel,
    fieldProps: ({ classes }) => ({ renderAs: 'Switch', className: classes.switch, label: specialConsiderationLabel }),
  },
  bahai_specialconsiderationreason: {
    isRequired: true,
    fieldProps: () => ({ maxLength: 100 }),
  },
  ...(addressFieldsConfig as AdditionalConfigType<Keys>),
};

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_email: rules.compose([rules.required, rules.email, rules.maxLength(100)]),
  bahai_phone: rules.compose([
    rules.conditionalRule((values) => !values.bahai_phoneinput, rules.required),
    rules.phone,
  ]),
  bahai_additionalemail: rules.compose([rules.email, rules.maxLength(100), rules.alternativeEmail('bahai_email')]),
  bahai_dateofbirth: rules.compose([rules.required, rules.pastDate]),
  bahai_firstname: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_lastname: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_nickname: rules.maxLength(30),
  bahai_note: rules.maxLength(2000),
  bahai_gendercode: rules.required,

  bahai_city: rules.compose([
    rules.conditionalRule(({ bahai_international }) => !bahai_international, rules.required),
    rules.maxLength(30),
  ]),
  bahai_stateid: rules.conditionalRule(({ bahai_international }) => !bahai_international, rules.required),
  bahai_addressline: rules.compose([
    rules.conditionalRule(({ bahai_international }) => !bahai_international, rules.required),
    rules.maxLength(500),
  ]),
  bahai_zipcode: rules.compose([
    rules.conditionalRule(({ bahai_international }) => !bahai_international, rules.required),
    rules.maxLength(10),
  ]),

  bahai_internationalcountry: rules.compose([rules.required, rules.maxLength(100)]),
  bahai_homeuniversitystate: rules.maxLength(500),
  bahai_specialconsiderationreason: rules.compose([rules.required, rules.maxLength(100)]),
};

export const getStatusFields = (): Keys[] => ['bahai_statuscode'];
export const getContactFields = (data: Record<Keys, any>) => [
  'bahai_email',
  ...((data.bahai_phoneinput ? ['bahai_phoneinput'] : ['bahai_phone']) as Keys[]),
];

export const getExtraFields = (): Keys[] => ['bahai_unitid'];

export const getCommonFields = (): Keys[] => ['bahai_isgpregionid', 'ownerid', 'createdon'];

export const getDetailsConfig: FormConfigGetter<Keys> = (data: Record<Keys, any>) => [
  [
    <Trans>Personal Information</Trans>,
    [
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_additionalemail',
      'bahai_dateofbirth',
      'bahai_age',
      'bahai_gendercode',
      'bahai_specialconsideration',
      ...((data.bahai_specialconsideration ? ['bahai_specialconsiderationreason'] : []) as Keys[]),
      'bahai_statusnote',
      'bahai_note',
    ],
  ],
  [
    <Trans>Address Information</Trans>,
    [
      'bahai_international',
      'bahai_addressline',
      'bahai_city',
      'bahai_stateid',
      'bahai_zipcode',
      'state.bahai_stateregionid',
      ...(!data.bahai_addressvalidationcode ? [] : (['bahai_addressvalidationmessage'] as Keys[])),
      ...((data.bahai_international ? ['bahai_internationalcountry'] : []) as Keys[]),
      'bahai_homeuniversitystate',
    ],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = (data: Record<Keys, any>) => [
  [
    <Trans>Profile Info</Trans>,
    [
      'bahai_email',
      'bahai_additionalemail',
      'bahai_phone',
      ...((data.bahai_phoneinput ? ['bahai_phoneinput'] : []) as Keys[]),
    ] as Keys[],
  ],
  [
    <Trans>Personal Information</Trans>,
    [
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_dateofbirth',
      'bahai_gendercode',
      'bahai_specialconsideration',
      ...((data.bahai_specialconsideration ? ['bahai_specialconsiderationreason'] : []) as Keys[]),
      'bahai_note',
    ],
  ],
  [
    <Trans>Address Information</Trans>,
    [
      'bahai_international',
      'bahai_addressline',
      'bahai_city',
      'bahai_stateid',
      'bahai_zipcode',
      ...((data.bahai_international ? ['bahai_internationalcountry'] : []) as Keys[]),
      'bahai_homeuniversitystate',
    ],
  ],
];

export const getTopMessages = (data: Record<string, any>) => {
  const messages = [] as TopMessage[];
  if (!isActive(data)) messages.push({ message: RecordStatusIsArchive, Icon: `archive` });
  if (isAfter(data.bahai_dateofbirth, subYears(new Date(), 18)))
    messages.push({
      message: <Trans>Person is under the age of 18</Trans>,
      type: 'success',
    });
  if (data.bahai_specialconsideration)
    messages.push({ message: specialConsiderationFlag, type: `warning`, Icon: `warning` });
  return messages;
};

export const FormImprover = () => (
  <>
    <ClearImprover
      sensitiveFields={['bahai_international']}
      fieldsToClear={['bahai_internationalcountry']}
      clearRequired={({ bahai_international }) => bahai_international === false}
    />
    <ClearImprover
      sensitiveFields={['bahai_specialconsideration']}
      fieldsToClear={['bahai_specialconsiderationreason']}
      clearRequired={({ bahai_specialconsideration }) => bahai_specialconsideration === true}
    />
  </>
);

export { getWarnings, preSubmit } from 'lib/addressValidation';

export const WarningsImprover = getWarningImprover(
  ['bahai_addressvalidationmessage', 'bahai_addressvalidationcode', 'bahai_phoneinput'],
  (values, setWarnings) => {
    const warnings: ReactNode[] = [];
    if (!!values.bahai_addressvalidationcode && values.bahai_addressvalidationmessage) {
      warnings.push(values.bahai_addressvalidationmessage);
    }
    if (values.bahai_phoneinput) {
      warnings.push(<Trans>Phone number is invalid. Please check and correct.</Trans>);
    }
    setWarnings(warnings);
  },
  true
);

export const isNotRemovable = (data: Record<Keys, any>) =>
  data.bahai_state === false ? false : <Trans>Person State must be Inactive to delete it</Trans>;
