import { TEntityName } from 'lib';
import React, { ReactNode, useCallback, useMemo } from 'react';
import classes from 'components/SinglePage/singlePage.module.scss';
import { Trans } from 'react-i18next';
import { useMetaData } from 'lib/hooks';
import { useRemoveAction } from 'settings/removableEntities';

const seminars =
  (record: 'Seminars' | 'Sessions' | 'Participants', action = 'delete') =>
  (data?: Record<string, any>) =>
    data ? (
      <Trans>
        Do you want to {{ action }} <strong className={classes.strong}>{data.bahai_name}</strong>? This is an
        irreversible action.
        <br />
        Please make sure that the Participants are aware of the change (if there are any already registered or informed
        Participants).
      </Trans>
    ) : (
      <Trans>
        Do you want to {{ action }} selected {{ record }}? This is an irreversible action.
        <br />
        Please make sure that the Participants are aware of the change (if there are any already registered or informed
        Participants).
      </Trans>
    );

const customRemoveConfirmation: Partial<Record<TEntityName, (data?: Record<string, any>) => ReactNode>> = {
  seminar: seminars('Seminars'),
  session: seminars('Sessions'),
  participant: seminars('Participants', 'remove'),
};

export const useRemoveConfirmation = (entityName: TEntityName) => {
  const { displayCollectionName, PrimaryNameAttribute } = useMetaData(entityName);

  const removeAction = useRemoveAction(entityName);
  const custom = useMemo(() => customRemoveConfirmation[entityName], [entityName]);

  return useCallback(
    (data?: Record<string, any>) => {
      if (custom) return custom(data);
      return data ? (
        <Trans>
          Do you want to {removeAction.toLowerCase()}{' '}
          <strong className={classes.strong}>{data[PrimaryNameAttribute] || data.bahai_id}</strong>? This is an
          irreversible action
        </Trans>
      ) : (
        <Trans>
          Do you want to {removeAction.toLowerCase()} selected {{ displayCollectionName }}? This is an irreversible
          action.
        </Trans>
      );
    },
    [PrimaryNameAttribute, custom, displayCollectionName, removeAction]
  );
};
