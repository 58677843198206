import entities from 'config';
import { config as personConfig } from 'schemas/person';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { TLinkEntity } from 'components/ListPage';
import Tooltip from 'components/Tooltip';
import { ReactComponent as InfoIcon } from './info.svg';
import { Trans } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';

type Keys = (typeof entities.participant.columns)[number];

type PersonKeys = `person.${(typeof entities.person.columns)[number]}`;

export const defaultMobileColumns: Array<Keys | PersonKeys> = [
  'bahai_personid',
  'person.bahai_phone',
  'bahai_attendancestatus',
];

export const links = {
  person: entities.person.columns,
  seminar: ['bahai_statuscode'],
} as TLinkEntity;

export const columns = [
  ...entities.participant.columns,
  ...entities.person.columns.map((column) => `person.${column}`),
];

const specialConsiderationLabel = (
  <>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        lineHeight: '20px',
      }}
    >
      <Trans>Special Consideration (Person)</Trans>
      <Tooltip content="Please, consult with Regional/National team for further information">
        <InfoIcon style={{ marginLeft: '5px' }} />
      </Tooltip>
    </div>
  </>
);

export const config: AdditionalConfigType<
  Keys | 'bahai_eventparticipant_imsid' | PersonKeys | 'seminar.bahai_statuscode'
> = {
  bahai_personid: {
    searchable: true,
  },
  bahai_sessionid: {
    hiddenForTable: true,
  },
  'seminar.bahai_statuscode': {
    hiddenForTable: true,
  },
  ...Object.fromEntries(
    Object.entries(personConfig).map(([key, props]) => ['person.' + key, { ...props, searchable: false }])
  ),
  'person.bahai_name': {
    hiddenForTable: true,
  },
  'person.bahai_specialconsideration': {
    label: specialConsiderationLabel,
  },
  'person.bahai_id': {
    component: ({ data }) => (
      <HistoryLink to={r.person({ id: data['_bahai_personid_value' as Keys] || 0 })}>
        {data['person.bahai_id']}
      </HistoryLink>
    ),
  },
};

export const getFormConfig: FormConfigGetter<Keys> = () => [];
