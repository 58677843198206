import { createContext, FC, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { TEntityName } from 'lib';

type Update = `${TEntityName}:${string}`;

const UpdatesContext = createContext({} as { fireUpdate: (key: Update | Update[]) => void; updates: Update[] });

export const useUpdates = () => useContext(UpdatesContext);

export const UpdatesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [updates, setUpdates] = useState<Update[]>([]);

  const timer = useRef<ReturnType<typeof setInterval>>();

  const fireUpdate = useCallback((update: Update | Update[]) => {
    clearTimeout(timer.current);
    setUpdates((v) => v.concat(...(Array.isArray(update) ? update : [update])));
    timer.current = setTimeout(() => setUpdates([]), 1000);
  }, []);

  return <UpdatesContext.Provider value={{ updates, fireUpdate }}>{children}</UpdatesContext.Provider>;
};
