import classes from './qty.module.scss';
import { Trans } from 'react-i18next';
import { useMetaData } from 'lib/hooks';
import { TEntityName } from 'lib';
import { TWidget } from 'components/SinglePage';

type Props = {
  data: Record<string, any>;
  entityName: TEntityName;
  fields: string[];
};

export const Participants: TWidget = ({ data }) => (
  <Numbers data={data} entityName={'session'} fields={[`bahai_participants`, `bahai_registrants`, `bahai_attendees`]} />
);

export const Numbers = ({ data, entityName, fields }: Props) => {
  const { getLabel } = useMetaData(entityName);

  return (
    <div className={classes.root}>
      {fields.map((field) => (
        <div key={field} className={classes.box_wrapper}>
          <div className={classes.qty}>{data[field] || 0}</div>
          <div className={classes.label}>
            <Trans>{getLabel(field)}</Trans>
          </div>
        </div>
      ))}
    </div>
  );
};
