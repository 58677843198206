import classes from './modal.module.scss';
import { ReactComponent as DismissIcon } from 'components/icons/dismiss.svg';
import { Button, ButtonPropsType } from 'components/Button';
import { FC, useCallback, useContext, useState, useRef, ReactNode } from 'react';
import cx from 'classnames';
import { Loader } from 'components/Loader';
import { createPortal } from 'react-dom';
import { ReactComponent as ZoomOutIcon } from 'components/Dialog/icons/zoom-out.svg';
import { ReactComponent as ZoomIcon } from 'components/Dialog/icons/zoom-in.svg';
import { ScreenContext } from 'providers/ScreenProvider';
import useDragging from 'components/Dragging';
import { ReactComponent as CollapseIcon } from 'components/Dialog/icons/collapse.svg';

type Control = {
  onClick?: () => void;
  title: ReactNode;
} & ButtonPropsType;

type TModal = {
  children?: ReactNode;
  onClose?: () => void;
  title?: ReactNode;
  header?: ReactNode;
  controls?: Array<Control | FC>;
  className?: string;
  loading?: boolean;
  portal?: boolean;
  fixedSize?: boolean;
  showZoom?: boolean;
  isDraggingAllowed?: boolean;
  collapsedLabel?: string;
  noPadding?: boolean;
};

const render = (content: JSX.Element, isPortal: boolean) => (isPortal ? createPortal(content, document.body) : content);

export const Modal: FC<TModal> = ({
  children,
  title,
  header,
  onClose,
  controls,
  className,
  loading = false,
  portal = true,
  fixedSize = false,
  showZoom = false,
  isDraggingAllowed = true,
  collapsedLabel,
  noPadding = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useContext(ScreenContext);
  const [isFullScreen, setIsFullScreen] = useState(isMobile ? showZoom : false);
  const toggleFullScreen = useCallback(() => setIsFullScreen((v) => !v), []);
  const dragProps = useDragging(ref, isDraggingAllowed);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const collapse = useCallback(() => {
    setIsCollapsed(true);
  }, []);
  const expand = useCallback(() => {
    setIsCollapsed(false);
  }, []);

  return render(
    <div>
      {isCollapsed ? (
        <>
          <div className={classes.overlay} />
          {showZoom && !isMobile && (
            <>
              <button type="button" className={classes.collapsed} onClick={expand}>
                {collapsedLabel}
              </button>
            </>
          )}
        </>
      ) : (
        <>
          <div className={classes.overlay} />
          <div
            ref={ref}
            className={cx(
              classes.root,
              { [classes.fixed]: fixedSize, [classes.fullScreen]: isFullScreen, [classes.noPadding]: noPadding },
              className
            )}
            {...dragProps}
          >
            {loading && (
              <div className={classes.loader}>
                <Loader />
              </div>
            )}
            <div className={classes.buttons}>
              {showZoom && !isMobile && (
                <>
                  <button type="button" className={classes.close} onClick={collapse}>
                    <CollapseIcon className={classes.icon} />
                  </button>
                  <button type="button" onClick={toggleFullScreen}>
                    {isFullScreen ? <ZoomOutIcon className={classes.icon} /> : <ZoomIcon className={classes.icon} />}
                  </button>
                </>
              )}
              {!!onClose && (
                <button type="button" onClick={onClose}>
                  <DismissIcon className={classes.icon} />
                </button>
              )}
            </div>
            {title && <div className={classes.header}>{title}</div>}
            {header && <div className={classes.content}>{header} </div>}
            {children && <div className={classes.children}>{children}</div>}
            {!!controls && (
              <div className={classes.controls}>
                {controls.map((ComponentOrObject, index) =>
                  typeof ComponentOrObject === 'function' ? (
                    <ComponentOrObject key={index} />
                  ) : (
                    <Button key={ComponentOrObject.title} {...ComponentOrObject}>
                      {ComponentOrObject.title}
                    </Button>
                  )
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>,
    portal
  );
};
