import SinglePage from 'components/SinglePage';
import * as config from 'schemas/session';
import { Trans, useTranslation } from 'react-i18next';

import * as participantConfig from 'schemas/participant';
import * as lecturerConfig from 'schemas/lecturer';
import { ApiFilter } from 'lib';
import ListPage from 'components/ListPage';
import { ReactNode, useCallback, useMemo } from 'react';
import { Action } from 'components/Actions';
import { useAttendance } from 'schemas/session/components';
import { useInviteParticipant } from 'schemas/common/inviteParticipant';
import { useParams } from 'react-router-dom';
import { useUpdates } from 'providers/UpdatesProvider';
import { SEMINAR_FINISHED } from 'schemas/seminar';
import { Keys } from 'schemas/session';

const ParticipantTab = ({
  bahai_sessionid,
  seminarId,
  entityName,
  message,
  leadFacilitator,
  yearsOffered,
}: {
  bahai_sessionid: string;
  seminarId: string;
  entityName: 'participant' | 'lecturer';
  message?: ReactNode;
  leadFacilitator?: string;
  yearsOffered?: string;
}) => {
  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_sessionid', value: bahai_sessionid }] }],
    [bahai_sessionid]
  );

  const { action: inviteAction, content: inviteContent } = useInviteParticipant({
    entityName,
    initialSessionId: bahai_sessionid,
    initialYear: yearsOffered,
    message,
    seminarId,
    leadFacilitator,
  });

  const { actions: attendanceActions } = useAttendance();

  const getActions = useCallback(
    (baseActions: Action[]) => [
      inviteAction,
      ...baseActions,
      ...(entityName === 'participant' ? attendanceActions : []),
    ],
    [attendanceActions, entityName, inviteAction]
  );

  const { fireUpdate } = useUpdates();
  const onItemsRemoved = useCallback(
    () => fireUpdate(['session:bahai_participants', 'seminar:bahai_participants']),
    [fireUpdate]
  );

  const isNotRemovable = useCallback(
    ({ ['seminar.bahai_statuscode']: status }: Record<Keys, any>) =>
      String(status) !== SEMINAR_FINISHED || entityName !== 'participant' ? (
        false
      ) : (
        <Trans>
          Seminar Status must be "Planning Phase" or "In Progress" to remove Participant in Session under it
        </Trans>
      ),
    [entityName]
  );

  return (
    <>
      {inviteContent}
      <ListPage
        systemView="Default for Session"
        isCreateHidden
        isSubgrid
        displayViews={false}
        allowRowClick={false}
        {...(entityName === 'participant' ? participantConfig : lecturerConfig)}
        {...{ getActions, hiddenFilters, onItemsRemoved, entityName, isNotRemovable }}
      />
    </>
  );
};

export const Session = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const getMessage = useCallback((data: Record<string, any>) => {
    if (String(data['seminar.bahai_statuscode']) === SEMINAR_FINISHED) return <Trans>Seminar Status is Finished</Trans>;
  }, []);

  const participantsContent = useCallback(
    (data: Record<string, any>) => (
      <ParticipantTab
        {...{
          bahai_sessionid: id,
          seminarId: data._bahai_seminarid_value,
          yearsOffered: data['bahai_yearoffered@OData.Community.Display.V1.FormattedValue'],
          entityName: 'participant',
          message: getMessage(data),
        }}
      />
    ),
    [getMessage, id]
  );

  const facilitatorsContent = useCallback(
    (data: Record<string, any>) => (
      <ParticipantTab
        {...{
          bahai_sessionid: id,
          seminarId: data._bahai_seminarid_value,
          entityName: 'lecturer',
          message: getMessage(data),
          leadFacilitator: data['seminar.bahai_leadfacilitatorid'],
        }}
      />
    ),
    [getMessage, id]
  );

  return (
    <SinglePage
      mainSectionName={<Trans>Basic Information</Trans>}
      entityName="session"
      asyncFields={['bahai_participants', 'bahai_registrants', 'bahai_attendees']}
      {...config}
      additionalTabs={[
        {
          label: t('Participants'),
          tab: 'participant',
          isDefault: true,
          content: participantsContent,
        },
        {
          label: t('Facilitators'),
          tab: 'facilitator',
          isDefault: true,
          content: facilitatorsContent,
        },
      ]}
    />
  );
};
