import { jwtDecode } from 'jwt-decode';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { loginRequest, msalConfig } from 'domain/authConfig';
import { AccountInfo, InteractionType, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import axios from 'axios';
import { baseURL } from 'domain/api';
import baseConfig from 'config/index';
import { Privilege, TEntityName } from 'lib';
import { ErrorPage } from 'components/ErrorBoundary/ErrorPage';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'components/ErrorBoundary/icons/Error403.svg';

import * as Sentry from '@sentry/react';
import { useSyncStorage } from 'lib/hooks';
import PopupBlockedPlaceholder from 'components/PupupBlockedPlaceholder';
import { debounce, devLog } from 'lib/helpers';
import { Loader } from 'components/Loader';

const secondsGap = 300; // 5 minutes
const isTokenValid = (token: string) => {
  try {
    const { exp = 0 } = jwtDecode(token);
    return exp > Date.now() / 1000 + secondsGap;
  } catch (e) {
    return false;
  }
};

type TAuthContext = {
  user: AccountInfo;
  getToken: () => Promise<string>;
  logout: () => void;
  privileges: Record<TEntityName, Privilege[]>;
  roles: string[];
  teams: string[];
  systemuserid: string;
  internalEmailAddress: string;
  fullName: string;
  entityImage: string;
} & TUserInfo;

type TUserInfo = {
  systemuserid: string;
  internalEmailAddress: string;
  fullName: string;
  entityImage: string;
  teams: string[];
  roles: string[];
  securityRoles: string[];
  businessUnitName: string;
};

const entityNames = Object.keys(baseConfig) as TEntityName[];

const getLogicalName = (name: TEntityName) => {
  switch (name) {
    default:
      return baseConfig[name].name;
  }
};

export const AuthContext = createContext({} as TAuthContext);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { error, acquireToken } = useMsalAuthentication(InteractionType.Redirect, {
    ...loginRequest,
    prompt: 'select_account',
  } as RedirectRequest);

  const [popupsBlocked, setPopupsBlocked] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (error && error.errorCode === 'popup_window_error') {
      setPopupsBlocked(true);
    }
  }, [error]);

  const { instance } = useMsal();
  const account = useAccount();
  const [userInfo, setUserInfo] = useSyncStorage<TUserInfo>('userInfo');
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState(false);
  const [removeDocumentsAllowed, setRemoveDocumentsAllowed] = useState(false);
  const [privileges, setPrivileges] = useState({
    ...Object.fromEntries(entityNames.map((name) => [name, [] as Privilege[]])),
    coordinator: [Privilege.Read],
  } as Record<TEntityName, Privilege[]>);
  const finalPrivileges = useMemo(
    () => ({
      ...privileges,
      document: removeDocumentsAllowed ? [Privilege.Read, Privilege.Delete] : [Privilege.Read],
    }),
    [privileges, removeDocumentsAllowed]
  );

  const logout = useMemo(
    () =>
      debounce(() => {
        setUserInfo();
        instance
          .logoutRedirect({
            authority: msalConfig.auth.authority,
            account: account,
            postLogoutRedirectUri: msalConfig.auth.redirectUri,
          })
          .then(() => devLog('Logout success or Popup Closed'))
          .catch((e) => {
            devLog('Logout Failure', e);
          });
      }, 300),
    [account, instance, setUserInfo]
  );

  const savedToken = useRef('');

  const getToken = useCallback(async () => {
    try {
      if (savedToken.current && isTokenValid(savedToken.current)) return savedToken.current;
      const result = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getActiveAccount() ?? undefined,
      } as SilentRequest);
      devLog('Base silent refresh success');
      savedToken.current = result?.accessToken;
    } catch (e) {
      try {
        const resp = await acquireToken(InteractionType.Redirect, loginRequest);
        devLog('Instance redirect refresh success');
        savedToken.current = resp?.accessToken || '';
      } catch (e) {
        console.error(e);
        //logout();
      }
    }
    return savedToken.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAccess = useCallback(async (token: string) => {
    devLog('Check Access');
    await axios.request({
      url: baseURL + `EntityDefinitions(LogicalName='systemuser')`,
      headers: { Authorization: 'Bearer ' + token },
      params: {
        $select: 'DisplayName',
      },
    });
  }, []);

  const getUserInfo = useCallback(async (localAccountId: string, token: string) => {
    const {
      data: {
        value: [
          {
            systemuserid,
            internalemailaddress,
            fullname,
            entityimage = '',
            teammembership_association,
            systemuserroles_association,
            businessunitid,
          },
        ],
      },
    } = await axios.request<{
      value: [
        {
          systemuserid: string;
          internalemailaddress: string;
          fullname: string;
          entityimage: string;
          bahai_securityrole: string;
          teammembership_association: { name: string }[];
          systemuserroles_association: { name: string }[];
          businessunitid: { name: string };
        },
      ];
    }>({
      url: baseURL + 'systemusers',
      params: {
        $select: 'systemuserid,internalemailaddress,fullname,entityimage',
        $filter: `azureactivedirectoryobjectid eq '${localAccountId}'`,
        $expand:
          'teammembership_association($select=name),systemuserroles_association($select=name),businessunitid($select=name)',
      },
      headers: { Authorization: 'Bearer ' + token },
    });

    return {
      systemuserid,
      internalemailaddress,
      fullname,
      entityimage,
      teammembership_association,
      systemuserroles_association,
      securityRoles: [],
      businessunitid,
    };
  }, []);

  const getACL = useCallback(async (userInfo: TUserInfo, token: string) => {
    setRemoveDocumentsAllowed(false);

    const {
      data: { RolePrivileges },
    } = await axios.get<{ RolePrivileges: Array<{ PrivilegeName: string }> }>(
      baseURL + `systemusers(${userInfo.systemuserid})/Microsoft.Dynamics.CRM.RetrieveUserPrivileges()`,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
    return RolePrivileges;
  }, []);

  const initiateUserInfo = useCallback(
    async (account: AccountInfo) => {
      const accessToken = await getToken();
      try {
        await checkAccess(accessToken);
        devLog('GET USER INFO');
        const userInfoResponse = await getUserInfo(account.localAccountId, accessToken);
        const userInfo = {
          fullName: userInfoResponse.fullname,
          entityImage: userInfoResponse.entityimage,
          systemuserid: userInfoResponse.systemuserid,
          internalEmailAddress: userInfoResponse.internalemailaddress,
          roles: userInfoResponse.systemuserroles_association.map((role) => role.name),
          teams: userInfoResponse.teammembership_association.map((team) => team.name),
          securityRoles: userInfoResponse.securityRoles,
          businessUnitName: userInfoResponse.businessunitid.name,
        };
        const acl = await getACL(userInfo, accessToken);

        setUserInfo(userInfo);
        setPrivileges((privileges) =>
          acl.reduce((acc, prvObj) => {
            const names = entityNames.filter((name) =>
              prvObj.PrivilegeName.endsWith(getLogicalName(name as TEntityName))
            );
            if (!names.length) return acc;
            const privilege = prvObj.PrivilegeName.slice(3).replace(getLogicalName(names[0]), '') as Privilege;
            return {
              ...acc,
              ...Object.fromEntries(names.map((name) => [name, [...acc[name], privilege]])),
            };
          }, privileges)
        );
      } catch (error: any) {
        devLog(error);
        setShowError(true);
      } finally {
        setLoading(false);
      }
    },
    [checkAccess, getACL, getToken, getUserInfo, setUserInfo]
  );

  useEffect(() => {
    if (account?.localAccountId) {
      initiateUserInfo(account).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showError) setShowError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  if (process.env.NODE_ENV === 'production') {
    Sentry.setUser(account);
  }

  if (popupsBlocked) return <PopupBlockedPlaceholder />;

  if (!loading && showError) {
    return (
      <ErrorPage
        title={<Trans>You don’t have access to this application</Trans>}
        description={
          <Trans>
            Current User {account?.username} doesn`t have access to this application. Please try to log in as another
            User or contact your regional coordinator. Please reload the page after the access will be granted by
            administrator.
          </Trans>
        }
        CustomIcon={ErrorIcon}
        showLogout={true}
        showRefresh={true}
        showGoHome={false}
      />
    );
  }

  return (
    <>
      {!loading ? (
        <AuthContext.Provider
          value={{
            getToken,
            logout,
            user: account ?? ({} as AccountInfo),
            privileges: finalPrivileges,
            ...(userInfo as TUserInfo),
          }}
        >
          <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
          <UnauthenticatedTemplate />
        </AuthContext.Provider>
      ) : (
        <Loader fullScreenOverlay text={<h3>{t('Authorization')}</h3>} />
      )}
    </>
  );
};

export const useSecurity = () => {
  const { privileges } = useContext(AuthContext);

  const isGranted = useCallback(
    (entity: TEntityName, privilege: Privilege) => privileges[entity].includes(privilege),
    [privileges]
  );

  return { isGranted };
};
