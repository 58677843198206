import SinglePage from 'components/SinglePage';
import * as config from 'schemas/facilitator';
import { useCallback } from 'react';
import { useArchiveAction, useChangeStatus } from 'schemas/common';
import { useParams } from 'react-router-dom';
import { Action } from 'components/Actions';
import { useApi } from 'domain/api';
import { useMetaData } from 'lib/hooks';
import { SEMINAR_FINISHED } from 'schemas/seminar';
import { Trans } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import classes from './style.module.scss';

export const Facilitator = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { request } = useApi();

  const { config: configs } = useMetaData();

  const checkArchiveAbility = useCallback(async () => {
    const {
      data: { value: withLead },
    } = await request<{ value: Array<{ bahai_seminarid: string; bahai_name: string }> }>({
      url: configs.seminar.url,
      params: {
        $select: 'bahai_seminarid,bahai_name',
        $filter: `_bahai_leadfacilitatorid_value eq ${id} and bahai_statuscode ne ${SEMINAR_FINISHED}`,
      },
    });
    const {
      data: { value: participants },
    } = await request<{ value: Array<{ bahai_seminarid: { bahai_seminarid: string; bahai_name: string } }> }>({
      url: configs.lecturer.url,
      params: {
        $expand: `bahai_seminarid($select=bahai_seminarid,bahai_name)`,
        $select: 'bahai_seminarid',
        $filter: `_bahai_facilitatorid_value eq ${id} and bahai_seminarid ne null and bahai_seminarid/bahai_statuscode ne ${SEMINAR_FINISHED}`,
      },
    });
    const seminars = withLead.concat(...participants.map((v) => v.bahai_seminarid));

    const seminarsContent = (
      <div className={classes.seminarList}>
        {seminars.map(({ bahai_seminarid, bahai_name }) => (
          <HistoryLink
            target="_blank"
            key={bahai_seminarid}
            to={r.seminar({ id: bahai_seminarid })}
            from={r.facilitator({ id })}
          >
            {bahai_name || '---'}
          </HistoryLink>
        ))}
      </div>
    );
    if (seminars.length > 0)
      throw (
        <Trans>
          Please remove this Facilitator from active Seminar first: {seminarsContent}
          Facilitator needs to be removed from the Seminar before he can be Archived.
        </Trans>
      );
  }, [configs.lecturer.url, configs.seminar.url, id, request]);

  const { actions: archiveActions, content: archiveContent } = useArchiveAction({
    id,
    entityName: 'facilitator',
    noteField: 'bahai_archivenote',
    preCheck: checkArchiveAbility,
  });

  const { action: statusAction, content: statusContent } = useChangeStatus({
    id,
    entityName: 'facilitator',
    fieldName: 'bahai_facilitatorstatus',
    online: true,
  });

  const getActions = useCallback(
    (baseActions: Action[]) => baseActions.concat(statusAction, ...archiveActions),
    [archiveActions, statusAction]
  );

  return (
    <>
      {archiveContent}
      {statusContent}
      <SinglePage
        entityName="facilitator"
        displayAssign
        {...{
          ...config,
          getActions,
        }}
      />
    </>
  );
};
