import { createContext, FC, ReactNode, useMemo } from 'react';
import { TAppEntityMeta, TJoinParams } from 'dynamics-meta';
import meta from 'domain/meta.json';
import { TEntityName } from 'lib';
import { useMetaOverrides } from 'settings/metaOverrides';

type TMetaDataContext = {
  config: Record<TEntityName, TAppEntityMeta>;
  joinParams: Record<string, TJoinParams>;
};

export const MetaDataContext = createContext({} as TMetaDataContext);

export const MetaDataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { targetCollections } = useMetaOverrides();

  const joinParams: Record<string, TJoinParams> = useMemo(() => {
    const baseParams = meta.targetCollections as unknown as Record<string, TJoinParams>;
    return Object.fromEntries(
      Object.entries(baseParams).map(([logicalName, params]) => [
        logicalName,
        { ...params, ...(targetCollections[logicalName] || {}) },
      ])
    );
  }, [targetCollections]);

  return (
    <MetaDataContext.Provider
      value={{
        config: meta.meta as unknown as Record<TEntityName, TAppEntityMeta>,
        joinParams,
      }}
    >
      {children}
    </MetaDataContext.Provider>
  );
};
