import {
  ApiFilter,
  Condition,
  FieldType,
  filtersConfig,
  PortalFilter,
  PortalFilterOperator,
  TEntityName,
  TFilterConfig,
  ViewSettings,
} from 'lib';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { ParsedQuery, useMetaData } from 'lib/hooks';
import { MIN_CELL_WIDTH, TableContext } from 'providers/TableProvider/index';
import { useApi } from 'domain/api';
import { View } from 'components/ListPage/hook';
import { UserSettingsContext } from 'providers/UserSettingsProvider';
import { TLinkEntity } from 'components/ListPage';
import { devLog } from 'lib/helpers';

export const useViews = (entityName: TEntityName, columns: readonly string[], systemView?: string) => {
  const { getViews: loadViews, getFieldType } = useMetaData(entityName, systemView);

  const parseFilters = useCallback(
    (xml: any[]): PortalFilter[] => {
      if (filtersConfig && xml.length) {
        const res: { filters: ApiFilter[]; conditions: Condition[] } = xml
          .map((v) => v.elements as Array<any>)
          .flat()
          .reduce(
            ({ filters, conditions }, v: any) => {
              {
                if (v.name === 'condition') {
                  const { attribute, entityname, operator } = v.attributes;
                  return {
                    filters,
                    conditions: [
                      ...conditions,
                      {
                        attribute: [entityname, attribute].filter((v) => !!v).join('.'),
                        operator,
                        value: v.elements
                          ? v.elements.map((v: any) => v.elements[0].text).join(',')
                          : v.attributes.value,
                      },
                    ],
                  };
                } else {
                  const { attribute, entityname } = v.elements[0].attributes;
                  return {
                    conditions,
                    filters: [
                      ...filters,
                      {
                        logicOperator: v.attributes.type as 'or' | 'and',
                        condition: [
                          {
                            attribute: [entityname, attribute].filter((v) => !!v).join('.'),
                            operator: v.elements.map((v: any) => v.attributes.operator).join(','),
                            value: v.elements.map((v: any) => v.attributes.value).join(','),
                          },
                        ],
                      },
                    ],
                  };
                }
              }
            },
            { conditions: [], filters: [] }
          );
        return res.conditions.map(({ attribute, operator, value, entityname }) => {
          const type = getFieldType([entityname, attribute].filter((v) => !!v).join('.')) || FieldType.String;
          const filterName = (Object.entries(filtersConfig) as [PortalFilterOperator, TFilterConfig][])
            .filter(
              ([filterName, { api, guesser }]) =>
                (guesser &&
                  guesser({
                    type,
                    operator,
                    value,
                  })) ||
                [api, filterName].includes(operator)
            )
            .map((v) => v[0])[0];
          const StringValue = Array.isArray(value) ? value.join(',') : value;
          const valueUpdate = filtersConfig[filterName]?.valueUpdate;
          return {
            operator: filterName,
            attribute,
            value: valueUpdate ? valueUpdate(type, StringValue) : StringValue,
          };
        });
      } else {
        return [];
      }
    },
    [getFieldType]
  );

  const parseLinks = useCallback((xml: any[]): TLinkEntity => {
    const entires = xml
      .map((x) => {
        const filters: any[] = x.elements.filter((v: any) => v.name === 'filter');

        const conditions: Condition[] = filters
          .map((v) => v.elements as Array<any>)
          .flat()
          .reduce((conditions, v: any) => {
            {
              if (v.name === 'condition') {
                const { attribute, operator } = v.attributes;
                return [
                  ...conditions,
                  {
                    attribute: attribute,
                    operator,
                    value: v.elements ? v.elements.map((v: any) => v.elements[0].text).join(',') : v.attributes.value,
                  },
                ];
              }
            }
          }, []);

        return [
          x.attributes.name,
          {
            from: x.attributes.from,
            to: x.attributes.to,
            condition: conditions,
            fields: [],
            linkType: x.attributes['link-type'],
          },
        ];
      })
      .filter(([, link]) => link.condition.length > 0);

    const links = Object.fromEntries(entires);
    return links as TLinkEntity;
  }, []);

  const mapDefaultQuery = useCallback(
    (query: ParsedQuery): View => {
      const { settings = [], sorting, filters, links, ...props } = query;
      const newColumns = settings.filter(({ name }) => !columns.includes(name)).map((v) => v.name);
      if (newColumns.length > 0) devLog(`New Columns for "${entityName}": ${newColumns.join(', ')}`);
      return {
        ...props,
        filters: filters ? parseFilters(filters) : [],
        sorting: sorting || [],
        search: '',
        columnsConfig: settings
          .filter(({ name }) => columns.includes(name))
          .map(({ name, width }) => ({
            name,
            visible: true,
            pinned: false,
            width: Number(width) || MIN_CELL_WIDTH,
          })),
        links: links ? parseLinks(links) : {},
      };
    },
    [columns, entityName, parseFilters, parseLinks]
  );

  const mapUserQuery = useCallback(
    ({
      bahai_portaluserqueryid: id,
      bahai_name: name,
      bahai_description: description,
      bahai_definition,
      bahai_isdefault: isDefault,
    }: ViewSettings): View => {
      const { columnsConfig, ...rest } = (
        bahai_definition ? JSON.parse(bahai_definition) : { columnsConfig: [], filters: [], sorting: [] }
      ) as Pick<View, 'filters' | 'columnsConfig' | 'sorting' | 'search'>;
      return {
        id,
        name,
        description,
        isSystem: false,
        isDefault: !!isDefault,
        ...rest,
        columnsConfig: columnsConfig.filter((v) => columns.includes(v.name)),
      };
    },
    [columns]
  );

  const getViews = useCallback(async () => {
    try {
      const { defaultQueries, userQueries } = await loadViews();
      return [...defaultQueries.map(mapDefaultQuery), ...userQueries.map(mapUserQuery)].reduce(
        (acc, next) => ({ ...acc, [String(next.id)]: next }),
        {} as Record<string, View>
      );
    } catch (e) {
      devLog(e);
      return {} as Record<string, View>;
    }
  }, [loadViews, mapDefaultQuery, mapUserQuery]);

  return { getViews };
};

export const useViewActions = (entityName: TEntityName) => {
  const {
    currentView,
    queryParams: { page: _, ...queryParams },
    setViews,
    columnsConfig,
    setCurrentView,
  } = useContext(TableContext);

  const { updateOrCreateView, deleteView } = useApi();
  const { t } = useTranslation();
  const { addNotification, addError } = useNotifications();

  const { logicalName } = useMetaData(entityName);

  const updateView = useCallback(
    async (data?: Partial<ViewSettings>, isNew?: boolean) => {
      try {
        const resp = await updateOrCreateView(
          {
            ...(isNew
              ? {
                  bahai_tablelogicalname: logicalName,
                  bahai_isdefault: false,
                }
              : {}),
            ...(data || {}),
            bahai_definition: JSON.stringify({ ...queryParams, columnsConfig }),
          },
          isNew ? undefined : currentView
        );

        const { bahai_name: name, bahai_description: description } = data || {};
        const updatedParams = Object.fromEntries(Object.entries({ name, description }).filter((v) => !!v[1]));
        const id = isNew ? (resp?.headers?.location.match(/\((.+)\)/)?.[1] as string) : (currentView as string);
        setViews &&
          setViews((views) => ({
            ...views,
            [id]: isNew
              ? ({ id, ...queryParams, ...updatedParams, columnsConfig } as View)
              : { ...views[id], ...queryParams, ...updatedParams, columnsConfig },
          }));
        if (isNew) setCurrentView(id);
        addNotification({
          title: isNew ? t('View was created') : t('Your changes have been saved'),
          type: NotificationType.SUCCESS,
        });
      } catch (e) {
        addError(t('Something went wrong'));
      }
    },
    [
      addError,
      addNotification,
      columnsConfig,
      currentView,
      logicalName,
      queryParams,
      setCurrentView,
      setViews,
      t,
      updateOrCreateView,
    ]
  );

  const updateViewById = useCallback(
    async (id: string, data: Partial<ViewSettings>) => {
      try {
        await updateOrCreateView(data, id);
        const { bahai_name: name, bahai_description: description } = data || {};
        const updatedParams = Object.fromEntries(Object.entries({ name, description }).filter((v) => !!v[1]));
        setViews && setViews((views) => ({ ...views, [id]: { ...views[id], ...updatedParams } }));
        addNotification({
          title: t('Your changes have been saved'),
          type: NotificationType.SUCCESS,
        });
      } catch (e) {
        addError(t('Something went wrong'));
        console.error(e);
      }
    },
    [addError, addNotification, setViews, t, updateOrCreateView]
  );

  const { defaultViews, pinView: saveViewPin } = useContext(UserSettingsContext);

  const pinView = useCallback(
    (id: string, reload = true) => {
      saveViewPin(entityName, id);
      if (currentView !== id && reload) setCurrentView(id);
    },
    [currentView, entityName, saveViewPin, setCurrentView]
  );

  const removeViewById = useCallback(
    (id: string) => {
      deleteView(id).then(() => {
        setViews && setViews(({ [id]: _, ...otherViews }) => otherViews);
        if (defaultViews[entityName] === id) {
          pinView(id, false);
        }
        addNotification({ title: t('View was deleted'), type: NotificationType.SUCCESS });
      });
    },
    [addNotification, defaultViews, deleteView, entityName, pinView, setViews, t]
  );

  return { pinView, deleteView, updateOrCreateView, updateView, updateViewById, removeViewById };
};
