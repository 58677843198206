// eslint-disable-next-line no-undef
const env = process.env.REACT_APP_ENV || 'dev';

export const params = {
  dev: {
    url: 'https://dev-bahai.com:3000',
    scope: 'https://dev-isgp.api.crm.dynamics.com',
  },
  qa: {
    url: 'https://qa-isgp.bahai.us',
    scope: 'https://qa-isgp.api.crm.dynamics.com',
  },
  uat: {
    url: 'https://uat-isgp.bahai.us',
    scope: 'https://uat-isgp.api.crm.dynamics.com',
  },
  rc: {
    url: 'https://qa-rc-isgp.bahai.us',
    scope: 'https://qa-rc-isgp.crm.dynamics.com',
  },
  prod: {
    url: 'https://isgp.bahai.us',
    scope: 'https://isgp.crm.dynamics.com',
  },
};

const { url, scope } = params[env];

export const API_URL = scope;

export const msalConfig = {
  auth: {
    clientId: 'b751f860-5f6d-4e84-9bd0-1722eebc194a',
    authority: 'https://login.microsoftonline.com/a88ed93c-f89e-455c-b935-6e2eff84d052',
    redirectUri: `${url}`,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: [`${scope}/user_impersonation`],
  extraScopesToConsent: ['https://analysis.windows.net/powerbi/api/Report.Read.All'],
};
