import { Modal } from 'components/Modal';
import { Field, Form } from 'react-final-form';
import * as rules from 'lib/rules';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { TEntityName } from 'lib';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { AutocompleteType } from 'components/AutoComplete/hooks';
import { useMetaData } from 'lib/hooks';
import { useApi } from 'domain/api';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { createSelect } from 'components/Form/Select';
import { useSystemUserId } from 'lib/helpers';
import classes from './assign.module.scss';
import { parseError } from 'lib/errorParser';

type TAssignModalProps = {
  entityName: TEntityName;
  onClose: (reload?: boolean) => void;
  id: string;
};

export const AssignModal = ({ entityName, onClose, id }: TAssignModalProps) => {
  const { t } = useTranslation();

  const { logicalName, displayName, PrimaryIdAttribute } = useMetaData(entityName);
  const { addActionFailed, addNotification } = useNotifications();
  const { assign } = useApi();
  const currentUserId = useSystemUserId();
  const onAssign = useCallback(
    async ({ type, ...data }: Record<string, any>) => {
      try {
        const assigneeLogicalName = type === 'me' ? 'systemuser' : data.ownerid.split('<|>')[0];
        const assigneePrimaryIdAttribute = `${assigneeLogicalName}id`;
        const assignee = { [assigneePrimaryIdAttribute]: type === 'me' ? currentUserId : data.ownerid.split('<|>')[1] };
        await assign(logicalName, { [PrimaryIdAttribute]: id }, assigneeLogicalName, assignee);
        onClose(true);
        addNotification({ type: NotificationType.SUCCESS, title: t('Your changes have been saved') });
      } catch (e) {
        addActionFailed(parseError(e));
      }
    },
    [PrimaryIdAttribute, addActionFailed, addNotification, assign, currentUserId, id, logicalName, onClose, t]
  );

  const component = useMemo(
    () =>
      createFormAutocomplete({
        entities: [
          {
            entityName: 'coordinator',
            links: {
              businessunit: {
                from: 'businessunitid',
                to: 'businessunitid',
                fields: [],
              },
            },
            filters: [
              {
                logicOperator: 'and',
                condition: [
                  { attribute: 'isdisabled', operator: 'eq', value: '0' },
                  { attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' },
                  { attribute: 'businessunit.name', operator: 'ne', value: 'Fund Development' },
                ],
              },
            ],
          },
          {
            entityName: 'team',
            links: {
              businessunit: {
                from: 'businessunitid',
                to: 'businessunitid',
                fields: [],
              },
            },
            filters: [
              {
                logicOperator: 'and',
                condition: [{ attribute: 'businessunit.parentbusinessunitid', operator: 'not-null' }],
              },
            ],
          },
        ],
        type: AutocompleteType.PartyList,
      }),
    []
  );

  const Select = useMemo(
    () =>
      createSelect(
        new Map<string, string | JSX.Element>([
          ['me', t('Me')],
          ['team', t('Coordinator or Unit')],
        ])
      ),
    [t]
  );

  return (
    <Form
      onSubmit={onAssign}
      initialValues={{ type: 'me' }}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            title={t('Assign {{ displayName }}?', {
              displayName,
            })}
            portal={false}
            onClose={() => onClose()}
            loading={submitting}
            className={classes.root}
            fixedSize={true}
            header={t('To whom would you like to assign it?')}
            controls={[
              { title: t('Assign'), type: 'submit', role: 'primary' },
              {
                title: t('Cancel'),
                onClick: () => onClose(),
              },
            ]}
          >
            <Field name="type" component={Select} label={t('Assign To')} validate={rules.required} required />
            <Field
              name="ownerid"
              disabled={values.type === 'me'}
              component={component}
              label={t('Coordinator or Unit')}
              validate={rules.conditionalRequired(({ type }) => type !== 'me')}
              required
            />
          </Modal>
        </form>
      )}
    />
  );
};
