const fields = ['bahai_note', 'bahai_seminarid', 'bahai_yearoffered'] as const;

const readOnly = [
  'bahai_id',
  'bahai_name',
  'ownerid',
  'bahai_unitid',
  'createdon',
  'bahai_isgpregionid',
  'bahai_participants',
  'bahai_registrants',
  'bahai_attendees',
] as const;

export default { fields: fields, columns: [...fields, ...readOnly] };
