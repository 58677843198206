import { addressFields } from './address';

const fields = [
  'bahai_applicationtypecode',
  'bahai_canattendfullseminar',
  'bahai_challengeattendfullseminar',
  'bahai_completedruhiinstitutebooks',
  'bahai_dateofbirth',
  'bahai_email',
  'bahai_firstname',
  'bahai_gendercode',
  'bahai_intendedplansupongraduation',
  'bahai_intentionforcollegestudiescode',
  'bahai_interestinseminar',
  'bahai_involvementinbahaicommunityactivities',
  'bahai_lastname',
  'bahai_majorfieldofstudy',
  'bahai_note',
  'bahai_otheryearinuniversitystudy',
  'bahai_personalmedicalconditions',
  'bahai_phone',
  'bahai_seminarexpectations',
  'bahai_sourceoftheseminar',
  'bahai_universityname',
  'bahai_universitystateid',
  'bahai_yearinuniversitystudycode',
  'bahai_phoneinput',
  'bahai_isdraft',
  'bahai_personid',
  ...addressFields,
] as const;

const readOnly = [
  'bahai_id',
  'bahai_name',
  'bahai_unitid',
  'bahai_age',
  'bahai_applicationstatuscode',
  'bahai_isgpregionid',
  'bahai_confirmationtext',
] as const;

export default { fields: fields, columns: [...fields, ...readOnly] };
