import { useTranslation } from 'react-i18next';
import { TJoinParams } from 'dynamics-meta';

export const useMetaOverrides = (): { targetCollections: Record<string, Partial<TJoinParams>> } => {
  const { t } = useTranslation();

  return {
    targetCollections: {
      team: {
        DisplayName: t('Unit'),
      },
      systemuser: {
        DisplayName: t('Coordinator'),
      },
    },
  };
};
