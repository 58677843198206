import { useApi } from 'domain/api';
import { useEffect } from 'react';
import { devLog } from 'lib/helpers';

import meta from 'domain/meta.json';
import { TAppEntityMeta } from 'dynamics-meta';
import { TEntityName } from 'lib';

const ignore = [
  'bahai_region',
  'bahai_historylog',
  'bahai_historylogds',
  'bahai_portaluserquery',
  'bahai_usersettings',
];

const ignoredFields: Array<`${TEntityName}.${string}`> = ['seminar.bahai_title', 'person.bahai_migrationid'];

const config = meta.meta as unknown as Record<TEntityName, TAppEntityMeta>;

export const MetaChecker = () => {
  const { request } = useApi();

  const currentEntities = Object.values(config).map((v) => v.logicalName);

  useEffect(() => {
    request<{ value: Record<'logicalname', string>[] }>({
      url: 'entities',
      params: {
        $select: 'logicalname',
        $filter: `startswith(name, 'bahai_')`,
      },
    }).then(({ data: { value } }) => {
      const entities = value.map((v) => v.logicalname);
      const newEntities = entities.filter((v) => !currentEntities.includes(v) && !ignore.includes(v));
      if (newEntities.length > 0) devLog('New entities:', newEntities);
      Promise.all(
        entities
          .filter((v) => !ignore.includes(v))
          .map(async (name) => {
            const filteredIgnoreFields = ignoredFields
              .map((v) => v.split('.'))
              .filter((v) => config[v[0] as TEntityName].logicalName === name)
              .map((v) => v[1]);
            const fields = Object.values(config).find((v) => v.logicalName === name)?.fields;
            const id = Object.values(config).find((v) => v.logicalName === name)?.PrimaryIdAttribute;
            const image = Object.values(config).find((v) => v.logicalName === name)?.PrimaryImageAttribute;
            const existedFields = [
              ...(fields ? Object.keys(fields) : []),
              id,
              image,
              `${image}id`,
              ...filteredIgnoreFields,
            ].filter((v) => !!v);
            const {
              data: { value },
            } = await request<{ value: Record<'LogicalName', string>[] }>({
              url: `EntityDefinitions(LogicalName='${name}')/Attributes`,
              params: {
                $select: 'LogicalName',
                $filter: 'AttributeOf eq null',
              },
            });
            const newFields = value
              .map((v) => v.LogicalName)
              .filter((v) => v.startsWith('bahai_') && !existedFields.includes(v) && v !== `${name}id`);
            return [name, newFields];
          })
      ).then((resp) => {
        resp.forEach(([name, fields]) => {
          if (fields.length > 0) devLog(`New fields for "${name}"`, fields);
        });
      });
    });
  }, [currentEntities, request]);

  return null;
};
