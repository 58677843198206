const fields = ['firstname', 'lastname', 'fullname', 'homephone', 'middlename'] as const;

const readOnly = [
  'isdisabled',
  'entityimage',
  'createdby',
  'createdon',
  'internalemailaddress',
  'businessunitid',
  'bahai_homeunitid',
  'bahai_isgpregion',
  'bahai_unit',
  'bahai_usstate',
  'bahai_wholecountry',
] as const;

export default { fields: fields, columns: [...fields, ...readOnly] };
