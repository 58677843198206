import { TWidget } from 'components/SinglePage';
import { useTranslation } from 'react-i18next';
import { useApi } from 'domain/api';
import { useMetaData } from 'lib/hooks';
import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { devLog } from 'lib/helpers';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import classes from './style.module.scss';
import { ReactComponent as Icon } from './icon.svg';
import cx from 'classnames';
import { useUpdates } from 'providers/UpdatesProvider';

type Response = {
  _bahai_sessionid_value: string;
  _bahai_facilitatorid_value: string;
  '_bahai_sessionid_value@OData.Community.Display.V1.FormattedValue': string;
  '_bahai_facilitatorid_value@OData.Community.Display.V1.FormattedValue': string;
};

export const Facilitators: TWidget = ({ data: { bahai_seminarid } }) => {
  const { t } = useTranslation();
  const { request } = useApi();
  const { url } = useMetaData('lecturer');

  const { updates } = useUpdates();

  const [data, setData] = useState<Array<{ label: string; id: string; facilitators: ReactNode[] }>>([]);
  const [collapsed, setCollapsed] = useState(false);
  const toggle = useCallback(() => setCollapsed((v) => !v), []);

  const getData = useCallback(() => {
    request<{ value: Response[] }>({
      url,
      params: {
        $filter: `_bahai_seminarid_value eq ${bahai_seminarid} and _bahai_facilitatorid_value ne null`,
        $orderby: '_bahai_sessionid_value,_bahai_facilitatorid_value',
      },
    })
      .then(({ data: { value } }) => {
        const sessions = Array.from(new Set(value.map((v) => v._bahai_sessionid_value)));
        const values = value.reduce(
          (acc, next: Response) => {
            const id = next._bahai_sessionid_value;
            return { ...acc, [id]: (acc[id] || []).concat(next) };
          },
          {} as Record<string, Response[]>
        );
        const result = sessions.map((id) => ({
          id: values[id][0]._bahai_sessionid_value,
          label: values[id][0]['_bahai_sessionid_value@OData.Community.Display.V1.FormattedValue'].slice(0, 6),
          facilitators: values[id].map(
            ({
              _bahai_facilitatorid_value: id,
              '_bahai_facilitatorid_value@OData.Community.Display.V1.FormattedValue': label,
            }) => (
              <HistoryLink key={id} to={r.facilitator({ id })} from={r.seminar({ id: bahai_seminarid })}>
                {label || '---'}
              </HistoryLink>
            )
          ),
        }));
        setData(result);
      })
      .catch((e) => {
        devLog(e);
      });
  }, [bahai_seminarid, request, url]);

  useEffect(() => {
    if (bahai_seminarid) getData();
  }, [bahai_seminarid, getData]);

  useEffect(() => {
    if (updates.some((v) => v === 'seminar:facilitators')) getData();
  }, [getData, updates]);

  if (data.length === 0) return null;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <button onClick={toggle} className={cx(classes.btn, { [classes.collapsed]: collapsed })} type="button">
          <Icon />
          {t('Session Facilitators')}
        </button>
        <div className={classes.line} />
      </div>
      {!collapsed && (
        <div className={classes.list}>
          {data.map(({ id, label, facilitators }) => (
            <Fragment key={id}>
              <div className={classes.label}>{label}</div>
              <div className={classes.links}>{facilitators}</div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
