import { addressFields } from './address';

const fields = [
  'bahai_currentoccupationorfieldofservice',
  'bahai_dateofbirth',
  'bahai_email',
  'bahai_firstname',
  'bahai_fluentlanguage',
  'bahai_gendercode',
  'bahai_graduatedegreecompletedcode',
  'bahai_othergraduatedegree',
  'bahai_graduatefieldofstudycompleted',
  'bahai_graduategraduationyear',
  'bahai_highschoolgraduationyear',
  'bahai_lastname',
  'bahai_nickname',
  'bahai_note',
  'bahai_otherlanguage',
  'bahai_phone',
  'bahai_priorexperience',
  'bahai_undergraduatefieldofstudy',
  'bahai_undergraduategraduationyear',
  'bahai_clearance',
  'bahai_renewaldate',
  'bahai_phoneinput',
  'bahai_internationalcountry',
  ...addressFields,
] as const;

const readOnly = [
  'bahai_id',
  'bahai_unitid',
  'bahai_name',
  'bahai_age',
  'bahai_facilitatorstatus',
  'bahai_profileimage',
  'bahai_isgpregionid',
  'bahai_state',
  'bahai_archivenote',
  'bahai_migrationid',
] as const;

export default { fields: fields, columns: [...fields, ...readOnly] };
