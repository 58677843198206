import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApi } from 'domain/api';
import { TEntityName } from 'lib';
import { devLog } from 'lib/helpers';
import * as Sentry from '@sentry/react';

type Settings = {
  id?: string;
  defaultViews: Record<TEntityName, string>;
  pageSize: number;
};

export const UserSettingsContext = createContext(
  {} as {
    defaultViews: Record<TEntityName, string | undefined>;
    pageSize: number;
    setPageSize: (records: number) => void;
    pinView: (entityName: TEntityName, id: string) => void;
    unitName?: string;
  }
);

export const UserSettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { getUserSettings, updateUserSettings, addUserSettings, getBusinessUnitName } = useApi();

  const idRef = useRef('');

  const [settings, setSettings] = useState({
    defaultViews: {},
    pageSize: 100,
  } as Settings);

  const { defaultViews, pageSize } = useMemo(() => settings, [settings]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { value },
        } = await getUserSettings();
        if (!value || value.length === 0) {
          console.warn('No user settings found, creating default settings.');

          const newSettings = {
            ...settings,
          } as Settings;

          const response = await addUserSettings(JSON.stringify(newSettings));
          idRef.current = response?.headers?.location?.match(/\((.+)\)/)?.[1] as string;

          setSettings(newSettings);
          return;
        }

        const { bahai_usersettingsid: id, bahai_body } = value.reverse()[0];
        idRef.current = id;
        if (bahai_body) {
          setSettings((v) => ({ ...v, ...JSON.parse(bahai_body) }));
        }
      } catch (e) {
        devLog(e);
        Sentry.captureMessage('Failed to get or create user settings');
        Sentry.captureException(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (idRef.current) {
      updateUserSettings(idRef.current, JSON.stringify(settings)).catch((e) => {
        console.error(e);
      });
    }
  }, [settings, updateUserSettings]);

  const pinView = useCallback(
    (entityName: TEntityName, viewId: string) => {
      setSettings((v) => ({
        ...v,
        defaultViews: {
          ...v.defaultViews,
          [entityName]: String(defaultViews[entityName]) === String(viewId) ? undefined : String(viewId),
        },
      }));
    },
    [defaultViews]
  );

  const setPageSize = useCallback((pageSize: number) => {
    setSettings((v) => ({ ...v, pageSize }));
  }, []);

  return (
    <UserSettingsContext.Provider value={{ defaultViews, pinView, setPageSize, pageSize }}>
      {children}
    </UserSettingsContext.Provider>
  );
};
