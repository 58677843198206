import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { FieldValidator } from 'final-form';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { Trans } from 'react-i18next';
import { AreaTooltipComponent, RawComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { useFormChanges } from 'lib/hooks';
import { useFormState } from 'react-final-form';
import { useEffect } from 'react';
import { AddressComponent, addressFieldsConfig } from 'lib/addressValidation';
import { Participants } from 'components/Numbers';
import { Facilitators } from 'pages/Seminar/components/Facilitators';
import { createFormAutocomplete } from 'components/Form/Autocomplete';

export type Keys = (typeof entities.seminar.columns)[number] | 'bahai_seminarid';

export const defaultMobileColumns: Keys[] = ['bahai_id', 'bahai_name', 'bahai_unitid'];

export const columns = entities.seminar.columns;

const UNDERGRADUATE = '370790000';
export const SEMINAR_IN_PROGRESS = '370790002';
export const SEMINAR_FINISHED = '370790003';
export const SEMINAR_PLANNED = '370790000';

export const isNotRemovable = ({ bahai_statuscode }: Record<Keys, any>) =>
  String(bahai_statuscode) === SEMINAR_PLANNED ? (
    false
  ) : (
    <Trans>Seminar Status must be "Planning Phase" to delete it</Trans>
  );

export const getInitialValues = async () => ({ bahai_typecode: UNDERGRADUATE });

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink to={r.seminar({ id: data.bahai_seminarid || 0 })}>{data.bahai_id}</HistoryLink>
    ),
  },
  bahai_name: {
    searchable: true,
  },
  bahai_calendaryear: {
    component: RawComponent,
  },
  bahai_leadfacilitatorid: {
    isRequired: true,
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'facilitator',
          filters: {
            bahai_state: 'true',
          },
        },
      ],
    }),
  },
  bahai_typecode: { isRequired: true, isDisabled: true },
  bahai_startdate: { isRequired: true },
  bahai_finishdate: { isRequired: true },
  bahai_note: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_buildingandroomlist: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      maxLength: 500,
    }),
  },
  bahai_facilityname: { fieldProps: () => ({ maxLength: 100 }) },
  bahai_yearsoffered: { sortable: false },
  ...{
    ...(addressFieldsConfig as AdditionalConfigType<Keys>),
    bahai_addressline: {
      isRequired: true,
      component: AddressComponent,
      fieldProps: () => ({ maxLength: 100 }),
    },
  },
};

export const isActive = () => true;

export const getStatusFields = () => ['bahai_typecode', 'bahai_statuscode'];

export const getCommonFields = (): Keys[] => ['bahai_isgpregionid', 'ownerid', 'createdon'];

export const FormImprover = () => {
  const { changes, update } = useFormChanges(['bahai_startdate']);
  const { values } = useFormState();

  useEffect(() => {
    if (changes.bahai_startdate) {
      update({
        bahai_calendaryear: changes.bahai_startdate.slice(-4),
        ...(!values.bahai_finishdate ? { bahai_finishdate: changes.bahai_startdate } : {}),
      });
    }
  }, [changes, update, values]);
  return null;
};

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_typecode: rules.required,
  bahai_startdate: rules.compose([rules.required, rules.futureDateDirtyOnly]),
  bahai_finishdate: rules.compose([
    rules.required,
    rules.futureDateDirtyOnly,
    rules.lessThanDate((values) => values.bahai_startdate, <Trans>Can’t be before Start Date</Trans>),
  ]),
  bahai_leadfacilitatorid: rules.required,
  bahai_note: rules.maxLength(2000),
  bahai_city: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_stateid: rules.required,
  bahai_zipcode: rules.compose([rules.required, rules.maxLength(10)]),
  bahai_addressline: rules.compose([rules.required, rules.maxLength(100)]),
  bahai_facilityname: rules.maxLength(100),
  bahai_buildingandroomlist: rules.maxLength(500),
};

export const getDetailsConfig: FormConfigGetter<Keys> = (data: Record<Keys, any>) => [
  [
    <Trans>General Information</Trans>,
    ['bahai_startdate', 'bahai_finishdate', 'bahai_leadfacilitatorid', 'bahai_yearsoffered', 'bahai_note'],
  ],
  [
    <Trans>Facility Address</Trans>,
    [
      'bahai_addressline',
      'bahai_facilityname',
      'bahai_buildingandroomlist',
      'bahai_city',
      'bahai_stateid',
      'bahai_zipcode',
      ...(!data.bahai_addressvalidationcode ? [] : (['bahai_addressvalidationmessage'] as Keys[])),
    ],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  [<Trans>Basic Information</Trans>, ['bahai_typecode']],
  [
    <Trans>General Information</Trans>,
    ['bahai_startdate', 'bahai_finishdate', 'bahai_leadfacilitatorid', 'bahai_note'],
  ],
  [
    <Trans>Facility Address</Trans>,
    [
      'bahai_addressline',
      'bahai_facilityname',
      'bahai_buildingandroomlist',
      'bahai_city',
      'bahai_stateid',
      'bahai_zipcode',
    ],
  ],
];

export const getExtraFields = (): Keys[] => ['bahai_unitid'];
export const widgets = [Participants, Facilitators];
export { getWarnings, preSubmit, WarningsImprover } from 'lib/addressValidation';

export const isNotEditable = (data: Record<Keys, any>, isAssign = true) => {
  if (String(data.bahai_statuscode) === SEMINAR_FINISHED && isAssign) return <Trans>Seminar Status is Finished</Trans>;
  return false;
};
