import ListPage from 'components/ListPage';
import * as config from 'schemas/person';
import { ALUMNI, APPLIED_NOT_ATTENDED } from 'schemas/person';
import { ReactNode } from 'react';
import { ApiFilter, Condition, TEntityName } from 'lib';
import meta from 'domain/meta.json';
import { TAppEntityMeta } from 'dynamics-meta';

const options = (
  meta.meta as unknown as Record<TEntityName, TAppEntityMeta>
).person.fields.bahai_yearscompleted.options.concat([[`${ALUMNI}`, 'Alumni']]);

export const getYearsCondition = (index: number): Condition =>
  index === 0
    ? { attribute: 'bahai_yearscompleted', operator: 'null' }
    : { attribute: 'bahai_yearscompleted', operator: 'in', value: options.slice(0, index).map(([v]) => v) };

const quickFilters: Array<{ label: ReactNode; filter: ApiFilter }> = options.map(([_, label], index) => ({
  label,
  filter: {
    condition: [
      getYearsCondition(index),
      { attribute: 'bahai_state', operator: 'ne', value: 'false' },
      { attribute: 'bahai_statuscode', operator: 'ne', value: `${APPLIED_NOT_ATTENDED}` },
    ],
  },
}));

export const PersonList = () => <ListPage entityName="person" {...{ quickFilters, ...config }} />;
