import classes from './header.module.scss';
import { ReactComponent as LogoBird } from './bird.svg';
import { ReactComponent as LogoBig } from './logo-big.svg';
import { ReactComponent as Brand } from './brand.svg';
import { ReactComponent as Dev } from './dev.svg';
import { ReactComponent as Qa } from './qa.svg';
import { ReactComponent as Uat } from './uat.svg';
import { useCallback, useContext, useState, useMemo } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import AccountInfo from './components/AccountInfo';
import { ScreenContext } from 'providers/ScreenProvider';

const env = process.env.REACT_APP_ENV || 'dev';

export const LogoBlock = () => {
  const { isMobile } = useContext(ScreenContext);

  const envLogo = (env: string) => {
    switch (env) {
      case `dev`:
        return (
          <div className={classes.envBlock}>
            <Dev />
          </div>
        );
      case `qa`:
        return (
          <div className={classes.envBlock}>
            <Qa />
          </div>
        );
      case `uat`:
        return (
          <div className={classes.envBlock}>
            <Uat />
          </div>
        );
      default:
        return isMobile ? (
          <>
            <LogoBird className={classes.icon} />
            <Brand />
          </>
        ) : (
          <>
            <LogoBird className={classes.icon} />
            <LogoBig />
          </>
        );
    }
  };

  return <div className={classes.logoBlock}>{envLogo(env)}</div>;
};

const Header = () => {
  const { entityImage, fullName } = useContext(AuthContext);

  const [accountInfoVisible, setAccountInfoVisible] = useState(false);

  const showAccountInfo = useCallback(() => {
    setAccountInfoVisible(true);
  }, []);

  const hideAccountInfo = useCallback(() => {
    setAccountInfoVisible(false);
  }, []);

  const image = useMemo(() => (entityImage ? 'data:image/jpg;base64,' + entityImage : undefined), [entityImage]);

  return (
    <header className={classes.header} id="header">
      <LogoBlock />
      <div className={classes.rightBlock}>
        <div className={classes.user} onClick={showAccountInfo} id="header_user">
          <div className={classes.placeholder}>
            {image ? (
              <img alt="image" src={image} />
            ) : (
              <>
                {fullName
                  ?.split(' ')
                  .map((v) => v.split('')[0])
                  .join('')}
              </>
            )}
          </div>
        </div>
        <AccountInfo visible={accountInfoVisible} onHide={hideAccountInfo} />
      </div>
    </header>
  );
};

export default Header;
